@charset "UTF-8";
.section {
  /*padding: $content-padding-sides-mobile;*/
  padding: 40px 0; }
  @media (min-width: 900px) {
    .section {
      padding: 40px 40px; } }
  @media (min-width: 1096px) {
    .section {
      padding: 40px 40px; } }
  .section--backgroundColor {
    background-color: #e5e7eb; }
  .section--backgroundColorDark {
    background-color: #000f3c; }

.grid-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 100%; }
  .grid-wrapper:after {
    clear: both;
    content: '';
    display: table; }
  .grid-wrapper--noSpacingTopBottom {
    padding-bottom: 0;
    padding-top: 0; }

.grid-container {
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 auto;
  margin-left: -10px;
  margin-right: -10px;
  min-width: 100%;
  position: relative; }
  @media (min-width: 1096px) {
    .grid-container {
      flex-flow: row wrap; } }

.grid-cell--1 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--1 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 8.33333%;
      width: 8.33333%; } }

.grid-cell--2 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--2 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 16.66667%;
      width: 16.66667%; } }

.grid-cell--3 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--3 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 25%;
      width: 25%; } }

.grid-cell--4 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--4 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 33.33333%;
      width: 33.33333%; } }

.grid-cell--5 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--5 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 41.66667%;
      width: 41.66667%; } }

.grid-cell--6 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--6 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 50%;
      width: 50%; } }

.grid-cell--7 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--7 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 58.33333%;
      width: 58.33333%; } }

.grid-cell--8 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--8 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 66.66667%;
      width: 66.66667%; } }

.grid-cell--9 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--9 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 75%;
      width: 75%; } }

.grid-cell--10 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--10 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 83.33333%;
      width: 83.33333%; } }

.grid-cell--11 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--11 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 91.66667%;
      width: 91.66667%; } }

.grid-cell--12 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--12 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 100%;
      width: 100%; } }

/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

menu, ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.section {
  /*padding: $content-padding-sides-mobile;*/
  padding: 40px 0; }
  @media (min-width: 900px) {
    .section {
      padding: 40px 40px; } }
  @media (min-width: 1096px) {
    .section {
      padding: 40px 40px; } }
  .section--backgroundColor {
    background-color: #e5e7eb; }
  .section--backgroundColorDark {
    background-color: #000f3c; }

.grid-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 100%; }
  .grid-wrapper:after {
    clear: both;
    content: '';
    display: table; }
  .grid-wrapper--noSpacingTopBottom {
    padding-bottom: 0;
    padding-top: 0; }

.grid-container {
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 auto;
  margin-left: -10px;
  margin-right: -10px;
  min-width: 100%;
  position: relative; }
  @media (min-width: 1096px) {
    .grid-container {
      flex-flow: row wrap; } }

.grid-cell--1 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--1 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 8.33333%;
      width: 8.33333%; } }

.grid-cell--2 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--2 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 16.66667%;
      width: 16.66667%; } }

.grid-cell--3 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--3 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 25%;
      width: 25%; } }

.grid-cell--4 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--4 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 33.33333%;
      width: 33.33333%; } }

.grid-cell--5 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--5 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 41.66667%;
      width: 41.66667%; } }

.grid-cell--6 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--6 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 50%;
      width: 50%; } }

.grid-cell--7 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--7 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 58.33333%;
      width: 58.33333%; } }

.grid-cell--8 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--8 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 66.66667%;
      width: 66.66667%; } }

.grid-cell--9 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--9 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 75%;
      width: 75%; } }

.grid-cell--10 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--10 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 83.33333%;
      width: 83.33333%; } }

.grid-cell--11 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--11 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 91.66667%;
      width: 91.66667%; } }

.grid-cell--12 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--12 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 100%;
      width: 100%; } }

@font-face {
  font-family: "Rational Display Book Bold";
  src: url(./../../assets/fonts/rational-display-book-bold.otf) format("opentype"); }

@font-face {
  font-family: "Rational Display Semi Bold";
  src: url(./../../assets/fonts/rational-display-semi-bold.otf) format("opentype"); }

@font-face {
  font-family: "Rational Text Book";
  src: url(./../../assets/fonts/rational-text-book.otf) format("opentype"); }

@font-face {
  font-family: "Rational Text Light";
  src: url(./../../assets/fonts/rational-text-light.otf) format("opentype"); }

@font-face {
  font-family: "Rational Text Medium";
  src: url(./../../assets/fonts/rational-text-medium.otf) format("opentype"); }

@font-face {
  font-family: "Rational Text Semi Bold";
  src: url(./../../assets/fonts/rational-text-semi-bold.otf) format("opentype"); }

@font-face {
  font-family: "NotoSansCJKjp Bold";
  src: url(./../../assets/fonts/NotoSansCJKjp-Bold.otf) format("opentype"); }

@font-face {
  font-family: "NotoSansCJKjp Medium";
  src: url(./../../assets/fonts/NotoSansCJKjp-Medium.otf) format("opentype"); }

@font-face {
  font-family: "NotoSansCJKjp Regular";
  src: url(./../../assets/fonts/NotoSansCJKjp-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Kuraray Japanese";
  src: url(./../../assets/fonts/rational-text-book.otf) format("opentype");
  unicode-range: U+0020 —007F; }

@font-face {
  font-family: "Kuraray Japanese";
  src: url(./../../assets/fonts/NotoSansCJKjp-Regular.otf) format("opentype"); }

* {
  box-sizing: border-box; }

html {
  color: #000f3c;
  font-size: 16px;
  font-family: "Rational Text Book", Arial, Helvetica, sans-serif;
  /*&::-webkit-scrollbar {
    display: none;
  }*/ }
  html[lang="jp"] {
    font-family: "Kuraray Japanese"; }

body {
  -ms-overflow-style: none;
  font-weight: normal;
  background-color: white;
  font-family: inherit; }

.page {
  display: flex;
  position: relative;
  width: 100%; }

.content {
  margin: 60px 0 0 0;
  width: 100%; }
  @media (min-width: 750px) {
    .content {
      margin: 20px 0 0 300px;
      width: calc(100% - 300px); } }
  .content--frontpage {
    margin: 0 0 0 0;
    width: 100%; }
    @media (min-width: 750px) {
      .content--frontpage {
        margin: 0 0 0 300px;
        width: calc(100% - 300px); } }
