@import "reset-css";
@import "@shared/grid.scss";

@font-face {
  font-family: "Rational Display Book Bold";
  src: url(./../../assets/fonts/rational-display-book-bold.otf)
    format("opentype");
}
@font-face {
  font-family: "Rational Display Semi Bold";
  src: url(./../../assets/fonts/rational-display-semi-bold.otf)
    format("opentype");
}
@font-face {
  font-family: "Rational Text Book";
  src: url(./../../assets/fonts/rational-text-book.otf) format("opentype");
}
@font-face {
  font-family: "Rational Text Light";
  src: url(./../../assets/fonts/rational-text-light.otf) format("opentype");
}
@font-face {
  font-family: "Rational Text Medium";
  src: url(./../../assets/fonts/rational-text-medium.otf) format("opentype");
}
@font-face {
  font-family: "Rational Text Semi Bold";
  src: url(./../../assets/fonts/rational-text-semi-bold.otf) format("opentype");
}

@font-face {
  font-family: "NotoSansCJKjp Bold";
  src: url(./../../assets/fonts/NotoSansCJKjp-Bold.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansCJKjp Medium";
  src: url(./../../assets/fonts/NotoSansCJKjp-Medium.otf) format("opentype");
}
@font-face {
  font-family: "NotoSansCJKjp Regular";
  src: url(./../../assets/fonts/NotoSansCJKjp-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Kuraray Japanese";
  src: url(./../../assets/fonts/rational-text-book.otf) format("opentype");
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: "Kuraray Japanese";
  src: url(./../../assets/fonts/NotoSansCJKjp-Regular.otf) format("opentype");
}

* {
  box-sizing: border-box;
}

html {
  color: $color-darkblue;
  font-size: 16px;
  font-family: "Rational Text Book", Arial, Helvetica, sans-serif;

  /*&::-webkit-scrollbar {
    display: none;
  }*/
  &[lang="jp"] {
    font-family: "Kuraray Japanese";
  }
}

body {
  -ms-overflow-style: none;
  font-weight: normal;
  background-color: white;
  font-family: inherit;
}

.page {
  display: flex;
  position: relative;
  width: 100%;
}

.content {
  margin: $spacing-large 0 0 0;
  width: 100%;

  @include respond-to("small") {
    margin: $spacing-small 0 0 $menu-width;
    width: calc(100% - #{$menu-width});
  }

  &--frontpage {
    margin: 0 0 0 0;
    width: 100%;

    @include respond-to("small") {
      margin: 0 0 0 $menu-width;
      width: calc(100% - #{$menu-width});
    }
  }
}
