$color-cyan: rgb(0, 176, 233);
$color-black: rgb(0, 0, 0);
$color-darkblue: rgb(0, 15, 60);
$color-darkblue-80: rgb(51, 63, 99);
$color-white: rgb(255, 255, 255);
$color-grey: rgb(229, 231, 235);
$color-dark-grey: rgb(179, 183, 196);
$color-yellow: rgb(247, 201, 94);
$color-dark-yellow: rgb(214, 176, 87);
$color-shade-c: #00ffe6;
$color-shade-d: #00cfff;
$color-shade-e: #8998f1;
$color-link-hover-light: rgb(102, 111, 138);

$breakpoints: (
  small: 750px,
  medium: 900px,
  large: 1096px,
);

$menu-width: 300px;

$content-padding-sides-mobile: 0;
$content-padding-sides-medium: 4rem;
$content-padding-sides-large: 10rem;

$box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

$module-margin-bottom: 1.5rem;

$text-size-large: 76px;
$text-line-height-large: 86px;
$text-size-large-mobile: 32px;
$text-line-height-large-mobile: 38px;

$text-size-medium: 38px;
$text-line-height-medium: 43px;
$text-size-medium-mobile: 24px;
$text-line-height-medium-mobile: 28px;

$copy-text-size: 18px;
$copy-text-line-height: 26px;
$copy-text-size-small: 14px;
$copy-text-line-height-small: 20px;

$spacing-largest: 80px;
$spacing-large: 60px;
$spacing-medium: 40px;
$spacing-small: 20px;

$section-padding-large: $spacing-large $spacing-large;

$text-width-paragraph: 70%;

@import "@shared/grid.scss";

.slider {
  height: auto;
  width: 100%;
  margin-bottom: 2rem;

  @include respond-to("large") {
    /*margin-bottom: 2rem;*/
  }

  &-pic {
    height: auto;
    max-width: 100%;
    width: 100%;

    &--border {
      border: 2px solid $color-grey;
    }
  }

  &-caption {
    font-size: $copy-text-size;
    line-height: $copy-text-line-height;
    margin-bottom: 0.63rem;
    min-height: 1.3rem;
    padding: 1.25rem 0 0 0;
    position: relative;

    &--small {
      font-size: $copy-text-size-small;
      line-height: $copy-text-line-height-small;
    }
  }

  &-pagination {
    padding-top: 1.26rem !important;
  }

  &--caption-small {
    .slider-caption {
      font-size: $copy-text-size-small;
      line-height: $copy-text-line-height-small;
    }
  }

  &--caption-center {
    .slider-caption {
      /*       margin-top: 3rem; */
      padding-top: 0;
      text-align: center;
    }

    .slider-pagination {
      bottom: auto;

      .slider-page {
        opacity: 1;
        &.is-active {
          background-color: $color-darkblue;
          transform: none;
        }
      }
    }
  }

  &--caption-left {
    .slider-caption {
      /* margin-top: 4rem; */
      padding-top: 0;
      text-align: left;
    }

    .slider-pagination {
      bottom: auto;

      .slider-page {
        opacity: 1;
        &.is-active {
          background-color: $color-darkblue;
          transform: none;
        }
      }

      li:last-of-type {
        .slider-page {
          margin-right: 0;
        }
      }
    }
  }

  &-page.splide__pagination__page {
    border: 1px solid $color-darkblue;
    height: 12px;
    margin-right: 0.63rem;
    transition: background-color 800ms;
    width: 12px;
    transition: transform 200ms;

    &:hover {
      transform: scale(1.15);
    }
  }

  &-arrows {
    position: absolute;
    /* bottom: auto;
    top: calc(100% + 10px); */
    transform: translateY(10px);
    width: 100%;
    padding-top: 2.5rem !important;
  }

  &-arrow.splide__arrow {
    background: transparent;
    border-radius: 0;
    height: 2rem;
    opacity: 1;
    transition: transform 200ms;
    width: 1rem;
    z-index: 2;

    svg {
      pointer-events: none;
      height: 2rem;
      width: 2rem;
      transition: transform 200ms;
    }

    &--prev {
      left: 0;

      svg {
        transform: scaleX(1);
      }

      &:enabled:hover {
        svg {
          transform: scaleX(1) translateX(-4px);
        }
      }
    }
    &--next {
      right: 0;

      svg {
        transform: scaleX(-1);
      }

      &:enabled:hover {
        svg {
          transform: scaleX(-1) translateX(-4px);
        }
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &-chevronIcon {
    &--prev {
    }
    &--next {
    }
  }
}

.grid-cell--6,
.grid-cell--4,
.grid-cell--3 {
  .slider {
    /*height: 100%;*/
    /* margin-bottom: 24px; */
    .slider-page {
      height: 12px;
      width: 12px;
    }

    .slider-pagination {
      bottom: auto;
    }

    .slider-arrows {
      /* bottom: auto;
      top: calc(100% + 10px); */
    }

    .slider-arrow {
      width: 1rem;
    }

    .slider-caption {
      /* margin-top: 2.2rem; */
      padding: 3.13rem 0 0 0;
    }
  }
}
