$color-cyan: rgb(0, 176, 233);
$color-black: rgb(0, 0, 0);
$color-darkblue: rgb(0, 15, 60);
$color-darkblue-80: rgb(51, 63, 99);
$color-white: rgb(255, 255, 255);
$color-grey: rgb(229, 231, 235);
$color-dark-grey: rgb(179, 183, 196);
$color-yellow: rgb(247, 201, 94);
$color-dark-yellow: rgb(214, 176, 87);
$color-shade-c: #00ffe6;
$color-shade-d: #00cfff;
$color-shade-e: #8998f1;
$color-link-hover-light: rgb(102, 111, 138);

$breakpoints: (
  small: 750px,
  medium: 900px,
  large: 1096px,
);

$menu-width: 300px;

$content-padding-sides-mobile: 0;
$content-padding-sides-medium: 4rem;
$content-padding-sides-large: 10rem;

$box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

$module-margin-bottom: 1.5rem;

$text-size-large: 76px;
$text-line-height-large: 86px;
$text-size-large-mobile: 32px;
$text-line-height-large-mobile: 38px;

$text-size-medium: 38px;
$text-line-height-medium: 43px;
$text-size-medium-mobile: 24px;
$text-line-height-medium-mobile: 28px;

$copy-text-size: 18px;
$copy-text-line-height: 26px;
$copy-text-size-small: 14px;
$copy-text-line-height-small: 20px;

$spacing-largest: 80px;
$spacing-large: 60px;
$spacing-medium: 40px;
$spacing-small: 20px;

$section-padding-large: $spacing-large $spacing-large;

$text-width-paragraph: 70%;

@import "@shared/grid.scss";

.menu {
  $color-blue: #000f3c;
  $color-navi-hover: rgb(102, 111, 138);
  $menu-margin-bottom: 1.25rem;

  position: fixed;
  background-color: white;
  border-left: 1px solid #eeeef1;
  color: $color-blue;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-family: "Rational Text Medium";
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  width: $menu-width;
  max-width: 100vw;
  z-index: 10;
  left: auto;
  right: 0;
  transform: translateX(100%);
  will-change: transform;
  transition: transform 0.5s;

  &--open {
    transform: translateX(0);
  }

  svg {
    pointer-events: none;
  }

  @include respond-to("small") {
    left: 0;
    right: auto;
    border-right: 1px solid #eeeef1;
    border-left: none;
    transform: translateX(0);
  }

  &-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: white;
    border-bottom: 1px solid #eeeef1;
    padding: 1.5rem 0 1rem 1.5rem;
    transform: translateY(0);
    will-change: transform;
    transition: transform 0.4s;

    &--hidden {
      transform: translateY(-100%);
    }

    @include respond-to("small") {
      display: none;
    }
  }

  &-languageSwitch {
    align-self: flex-start;
    border: 1px solid transparent;
    border-radius: 5px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 1rem;
    margin-left: 1.75rem;
  }

  &-languageSwitchItem {
    color: #000f3c;
    background-color: #e6e8eb;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding: 6px 12px;
    text-transform: uppercase;

    &:hover {
      background-color: #fff;
    }

    &.is-active {
      background-color: #000f3c;
      color: #fff;

      &:hover {
        background-color: #000f3c;
      }
    }
  }

  &-burger {
    position: fixed;
    top: 1rem;
    right: 1rem;
    color: #000f3c;
    height: 2.5rem;
    width: 2.5rem;
    z-index: 11;
    cursor: pointer;
    transform: rotate(0);
    will-change: transform;
    transition: transform 0.4s;

    &--hidden {
      transform: translateY(-4.5rem);
    }

    @include respond-to("small") {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0.75rem;
      left: 0.5rem;
      right: 0.5rem;
      border-bottom: 2px solid currentColor;
      margin-bottom: -2px;
      opacity: 1;
      will-change: opacity;
      transition: opacity 0.4s;
    }

    &:after {
      top: auto;
      bottom: 0.75rem;
    }

    &--open {
      transform: rotate(-90deg);

      &:before,
      &:after {
        opacity: 0;
      }
    }

    &Bar {
      position: absolute;
      top: 1.25rem;
      left: 0.5rem;
      right: 0.5rem;

      &:before,
      &:after {
        content: "";
        display: block;
        border-bottom: 2px solid currentColor;
        margin-bottom: -2px;
        transform: rotate(0);
        will-change: transform;
        transition: transform 0.4s;
      }

      .menu-burger--open & {
        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(-135deg);
        }
      }
    }
  }

  &-second {
    margin-left: 1rem;
  }

  &-logo {
    margin: 1.75rem;
    opacity: 0;

    @include respond-to("small") {
      opacity: 1;
    }

    &Link {
      color: black;
      display: block;
      text-decoration: none;
    }

    &Icon {
      height: 32px;
      width: 129px;
    }
  }

  &-items {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3rem;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.75) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      pointer-events: none;
    }
  }

  &-layers {
    margin-bottom: 20px;
    position: relative;
    flex-grow: 1;
  }

  &-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &--secondary {
      top: 3rem;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 0;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .menu-item {
        padding-bottom: 0;
        padding-top: 0;
        padding-left: 0;

        a {
          padding-left: 1.75rem;
        }
      }
    }
  }

  &-item {
    border-bottom: 1px solid #ebecef;
    cursor: pointer;
    line-height: 1;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 3.5rem;
    will-change: transform;
    position: relative;
    transition: color 200ms;

    &:hover {
      color: $color-navi-hover;
    }

    a {
      padding-bottom: 1rem;
      padding-top: 1rem;
      padding-left: 3.5rem;
      text-decoration: none;
      display: block;
      color: inherit;
      transition: color 200ms;

      &:hover {
        color: $color-navi-hover;
      }
    }

    span {
      display: inline-block;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        border-bottom: 2px solid $color-yellow;
        transform: scaleX(0);
        transform-origin: right top;
        transition: transform 0.35s 0.15s cubic-bezier(0.06, 0.18, 0.065, 1);
        will-change: transform;
      }
    }

    &.is-active {
      span:after {
        transform: scaleX(1);
      }
    }
  }

  &-search {
    align-items: center;
    display: flex;
    font-family: "Rational Text Semi Bold";
    font-weight: normal;
    padding-bottom: 5px;
    padding-top: 5px;
    position: relative;
    transition: color 200ms;

    &:hover {
      color: $color-navi-hover;
    }
  }
  &-searchIcon {
    top: 45% !important;
    transform: translateY(-50%);
  }
  &-searchField {
    border: none;
    color: #000f3c;
    font-size: inherit;
    font-family: inherit;
    padding: 0.5rem 0;

    &::-moz-placeholder {
      color: #000f3c;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #000f3c;
      opacity: 1;
    }

    &::placeholder {
      color: #000f3c;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #000f3c;
    }
    &::-ms-input-placeholder {
      color: #000f3c;
    }
    &:focus {
      outline: none;
    }
  }

  &-main {
    align-items: center;
    display: flex;
    font-family: "Rational Text Semi Bold";
    font-weight: normal;
  }

  &-icon {
    left: 1.75rem;
    top: 1rem;
    height: 1rem;
    position: absolute;
    transition: transform 250ms linear;
    width: 1rem;

    &--right {
      left: auto;
      right: 1rem;
    }
    &--up {
      transform: rotate(180deg);
    }
    &--rotate {
      transform: rotate(180deg);
    }
  }

  &-primary {
    color: $color-darkblue;
    cursor: pointer;
    text-decoration: none;
    transition: color 200ms;

    &.is-active {
      font-family: "Rational Text Semi Bold";
      font-weight: normal;

      a:after {
        transform: scaleX(1);
      }

      &.has-children {
        background-color: #f2f3f5;
        border-bottom-color: #f2f3f5;
      }
    }
    &.is-expanded {
      background-color: #f2f3f5;
      border-bottom-color: #f2f3f5;
      font-family: "Rational Text Semi Bold";
      font-weight: normal;
    }
  }

  &-secondary {
    background-color: #f2f3f5;
    border-bottom-color: #f2f3f5;
    font-family: "Rational Text Book", Arial, Helvetica, sans-serif;

    a {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }

    &.is-active {
      background-color: #f2f3f5;
      font-family: "Rational Text Semi Bold";
      font-weight: normal;
    }
    &.is-last-child {
      border-bottom-color: #ebecef;

      a {
        padding-bottom: 1.25rem;
      }
    }
  }
  &-searchOverlay {
    background-color: #fff;
    position: absolute;
    bottom: 1rem;
    overflow-y: auto;
    top: 3rem;
    left: 0;
    right: 0;
    z-index: 2;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-searchResult {
    color: #000f3c;
    text-decoration: none;
    display: block;
    font-family: "Rational Text Semi Bold";
    font-weight: normal;
    border-bottom: 1px solid #ebecef;
    padding-bottom: 1rem;
    padding-left: 3.5rem;
    padding-top: 1rem;
  }
}
