.section {
  /*padding: $content-padding-sides-mobile;*/
  padding: 40px 0; }
  @media (min-width: 900px) {
    .section {
      padding: 40px 40px; } }
  @media (min-width: 1096px) {
    .section {
      padding: 40px 40px; } }
  .section--backgroundColor {
    background-color: #e5e7eb; }
  .section--backgroundColorDark {
    background-color: #000f3c; }

.grid-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 100%; }
  .grid-wrapper:after {
    clear: both;
    content: '';
    display: table; }
  .grid-wrapper--noSpacingTopBottom {
    padding-bottom: 0;
    padding-top: 0; }

.grid-container {
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 auto;
  margin-left: -10px;
  margin-right: -10px;
  min-width: 100%;
  position: relative; }
  @media (min-width: 1096px) {
    .grid-container {
      flex-flow: row wrap; } }

.grid-cell--1 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--1 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 8.33333%;
      width: 8.33333%; } }

.grid-cell--2 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--2 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 16.66667%;
      width: 16.66667%; } }

.grid-cell--3 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--3 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 25%;
      width: 25%; } }

.grid-cell--4 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--4 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 33.33333%;
      width: 33.33333%; } }

.grid-cell--5 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--5 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 41.66667%;
      width: 41.66667%; } }

.grid-cell--6 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--6 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 50%;
      width: 50%; } }

.grid-cell--7 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--7 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 58.33333%;
      width: 58.33333%; } }

.grid-cell--8 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--8 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 66.66667%;
      width: 66.66667%; } }

.grid-cell--9 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--9 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 75%;
      width: 75%; } }

.grid-cell--10 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--10 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 83.33333%;
      width: 83.33333%; } }

.grid-cell--11 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--11 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 91.66667%;
      width: 91.66667%; } }

.grid-cell--12 {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1096px) {
    .grid-cell--12 {
      flex: 0 0 auto;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      max-width: 100%;
      width: 100%; } }

.slider {
  height: auto;
  width: 100%;
  margin-bottom: 2rem; }
  @media (min-width: 1096px) {
    .slider {
      /*margin-bottom: 2rem;*/ } }
  .slider-pic {
    height: auto;
    max-width: 100%;
    width: 100%; }
    .slider-pic--border {
      border: 2px solid #e5e7eb; }
  .slider-caption {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0.63rem;
    min-height: 1.3rem;
    padding: 1.25rem 0 0 0;
    position: relative; }
    .slider-caption--small {
      font-size: 14px;
      line-height: 20px; }
  .slider-pagination {
    padding-top: 1.26rem !important; }
  .slider--caption-small .slider-caption {
    font-size: 14px;
    line-height: 20px; }
  .slider--caption-center .slider-caption {
    /*       margin-top: 3rem; */
    padding-top: 0;
    text-align: center; }
  .slider--caption-center .slider-pagination {
    bottom: auto; }
    .slider--caption-center .slider-pagination .slider-page {
      opacity: 1; }
      .slider--caption-center .slider-pagination .slider-page.is-active {
        background-color: #000f3c;
        transform: none; }
  .slider--caption-left .slider-caption {
    /* margin-top: 4rem; */
    padding-top: 0;
    text-align: left; }
  .slider--caption-left .slider-pagination {
    bottom: auto; }
    .slider--caption-left .slider-pagination .slider-page {
      opacity: 1; }
      .slider--caption-left .slider-pagination .slider-page.is-active {
        background-color: #000f3c;
        transform: none; }
    .slider--caption-left .slider-pagination li:last-of-type .slider-page {
      margin-right: 0; }
  .slider-page.splide__pagination__page {
    border: 1px solid #000f3c;
    height: 12px;
    margin-right: 0.63rem;
    transition: background-color 800ms;
    width: 12px;
    transition: transform 200ms; }
    .slider-page.splide__pagination__page:hover {
      transform: scale(1.15); }
  .slider-arrows {
    position: absolute;
    /* bottom: auto;
    top: calc(100% + 10px); */
    transform: translateY(10px);
    width: 100%;
    padding-top: 2.5rem !important; }
  .slider-arrow.splide__arrow {
    background: transparent;
    border-radius: 0;
    height: 2rem;
    opacity: 1;
    transition: transform 200ms;
    width: 1rem;
    z-index: 2; }
    .slider-arrow.splide__arrow svg {
      pointer-events: none;
      height: 2rem;
      width: 2rem;
      transition: transform 200ms; }
    .slider-arrow.splide__arrow--prev {
      left: 0; }
      .slider-arrow.splide__arrow--prev svg {
        transform: scaleX(1); }
      .slider-arrow.splide__arrow--prev:enabled:hover svg {
        transform: scaleX(1) translateX(-4px); }
    .slider-arrow.splide__arrow--next {
      right: 0; }
      .slider-arrow.splide__arrow--next svg {
        transform: scaleX(-1); }
      .slider-arrow.splide__arrow--next:enabled:hover svg {
        transform: scaleX(-1) translateX(-4px); }
    .slider-arrow.splide__arrow:disabled {
      cursor: default;
      opacity: 0.5; }

.grid-cell--6 .slider,
.grid-cell--4 .slider,
.grid-cell--3 .slider {
  /*height: 100%;*/
  /* margin-bottom: 24px; */ }
  .grid-cell--6 .slider .slider-page,
  .grid-cell--4 .slider .slider-page,
  .grid-cell--3 .slider .slider-page {
    height: 12px;
    width: 12px; }
  .grid-cell--6 .slider .slider-pagination,
  .grid-cell--4 .slider .slider-pagination,
  .grid-cell--3 .slider .slider-pagination {
    bottom: auto; }
  .grid-cell--6 .slider .slider-arrows,
  .grid-cell--4 .slider .slider-arrows,
  .grid-cell--3 .slider .slider-arrows {
    /* bottom: auto;
      top: calc(100% + 10px); */ }
  .grid-cell--6 .slider .slider-arrow,
  .grid-cell--4 .slider .slider-arrow,
  .grid-cell--3 .slider .slider-arrow {
    width: 1rem; }
  .grid-cell--6 .slider .slider-caption,
  .grid-cell--4 .slider .slider-caption,
  .grid-cell--3 .slider .slider-caption {
    /* margin-top: 2.2rem; */
    padding: 3.13rem 0 0 0; }
