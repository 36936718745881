$color-cyan: rgb(0, 176, 233);
$color-black: rgb(0, 0, 0);
$color-darkblue: rgb(0, 15, 60);
$color-darkblue-80: rgb(51, 63, 99);
$color-white: rgb(255, 255, 255);
$color-grey: rgb(229, 231, 235);
$color-dark-grey: rgb(179, 183, 196);
$color-yellow: rgb(247, 201, 94);
$color-dark-yellow: rgb(214, 176, 87);
$color-shade-c: #00ffe6;
$color-shade-d: #00cfff;
$color-shade-e: #8998f1;
$color-link-hover-light: rgb(102, 111, 138);

$breakpoints: (
  small: 750px,
  medium: 900px,
  large: 1096px,
);

$menu-width: 300px;

$content-padding-sides-mobile: 0;
$content-padding-sides-medium: 4rem;
$content-padding-sides-large: 10rem;

$box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

$module-margin-bottom: 1.5rem;

$text-size-large: 76px;
$text-line-height-large: 86px;
$text-size-large-mobile: 32px;
$text-line-height-large-mobile: 38px;

$text-size-medium: 38px;
$text-line-height-medium: 43px;
$text-size-medium-mobile: 24px;
$text-line-height-medium-mobile: 28px;

$copy-text-size: 18px;
$copy-text-line-height: 26px;
$copy-text-size-small: 14px;
$copy-text-line-height-small: 20px;

$spacing-largest: 80px;
$spacing-large: 60px;
$spacing-medium: 40px;
$spacing-small: 20px;

$section-padding-large: $spacing-large $spacing-large;

$text-width-paragraph: 70%;

@import "@shared/grid.scss";

.downloadList {
    background-color: $color-darkblue;
    color: $color-white;
    padding: 0;

    @include respond-to("medium") {
      padding: 2rem 0 2rem 0;
    }

    &-wrapper {
      background-color: $color-darkblue;
    }

    &-richtext {
        margin-bottom: 2rem;
    }

    &-item {
        align-items: center;
        border-bottom: 1px solid $color-white;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 1rem 0;
        transition: 200ms;

        &:first-of-type {
            margin-top: 1rem;
        }

        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
        }

        &:hover {
          color: rgb(178, 183, 196);

          .downloadList-downloadIcon {
            transition: 200ms;
            color: rgb(178, 183, 196);
            transform: scale(1.1);
          }
        }

        &Name {
            margin-right: auto;
        }

        &File {
          min-width: 80px;

          &Type {
            text-transform: uppercase;
          }

          &Size {}
        }

        &Control {
            margin-left: 1rem;

            &Link {}
        }
    }

    &-downloadIcon {
      color: white;
      height: 45px;
      width: 45px;
    }
}
