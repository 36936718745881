$color-cyan: rgb(0, 176, 233);
$color-black: rgb(0, 0, 0);
$color-darkblue: rgb(0, 15, 60);
$color-darkblue-80: rgb(51, 63, 99);
$color-white: rgb(255, 255, 255);
$color-grey: rgb(229, 231, 235);
$color-dark-grey: rgb(179, 183, 196);
$color-yellow: rgb(247, 201, 94);
$color-dark-yellow: rgb(214, 176, 87);
$color-shade-c: #00ffe6;
$color-shade-d: #00cfff;
$color-shade-e: #8998f1;
$color-link-hover-light: rgb(102, 111, 138);

$breakpoints: (
  small: 750px,
  medium: 900px,
  large: 1096px,
);

$menu-width: 300px;

$content-padding-sides-mobile: 0;
$content-padding-sides-medium: 4rem;
$content-padding-sides-large: 10rem;

$box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

$module-margin-bottom: 1.5rem;

$text-size-large: 76px;
$text-line-height-large: 86px;
$text-size-large-mobile: 32px;
$text-line-height-large-mobile: 38px;

$text-size-medium: 38px;
$text-line-height-medium: 43px;
$text-size-medium-mobile: 24px;
$text-line-height-medium-mobile: 28px;

$copy-text-size: 18px;
$copy-text-line-height: 26px;
$copy-text-size-small: 14px;
$copy-text-line-height-small: 20px;

$spacing-largest: 80px;
$spacing-large: 60px;
$spacing-medium: 40px;
$spacing-small: 20px;

$section-padding-large: $spacing-large $spacing-large;

$text-width-paragraph: 70%;

@import "@shared/grid.scss";

.startpageHero {
  position: relative;
  overflow-x: hidden;

  &-image {
    display: block;
    height: auto;
    width: auto;

    @include respond-to("medium") {
      width: 100%;
    }
  }

  &-contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &-content {
    color: white;
    width: 100%;
    max-width: 900px;
    padding: 2rem;
    margin-top: 76px; // counter mobile nav height

    @include respond-to("medium") {
      margin-top: 0;
    }
  }

  &-headline {
    font-family: "Rational Text Medium";
    font-size: $text-size-medium;
    line-height: $text-line-height-medium;
    margin-bottom: 1rem;

    @include respond-to("large") {
      font-size: $text-size-large;
      line-height: $text-line-height-large;
    }
  }

  &-text {
    font-size: $copy-text-size;
    line-height: $copy-text-line-height;
    width: 100%;
    max-width: 600px;
  }

  &-buttonWrapper {
    margin-top: 2rem;
  }

  &-button {
    background-color: $color-yellow;
    border-radius: 5px;
    color: $color-darkblue;
    font-size: $copy-text-size;
    line-height: $copy-text-line-height;
    padding: 0.85em 1.2em 0.75em;
    text-decoration: none;
    transition: background-color 200ms;

    &:hover {
      background-color: $color-dark-yellow;
    }
  }
}
