$color-cyan: rgb(0, 176, 233);
$color-black: rgb(0, 0, 0);
$color-darkblue: rgb(0, 15, 60);
$color-darkblue-80: rgb(51, 63, 99);
$color-white: rgb(255, 255, 255);
$color-grey: rgb(229, 231, 235);
$color-dark-grey: rgb(179, 183, 196);
$color-yellow: rgb(247, 201, 94);
$color-dark-yellow: rgb(214, 176, 87);
$color-shade-c: #00ffe6;
$color-shade-d: #00cfff;
$color-shade-e: #8998f1;
$color-link-hover-light: rgb(102, 111, 138);

$breakpoints: (
  small: 750px,
  medium: 900px,
  large: 1096px,
);

$menu-width: 300px;

$content-padding-sides-mobile: 0;
$content-padding-sides-medium: 4rem;
$content-padding-sides-large: 10rem;

$box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

$module-margin-bottom: 1.5rem;

$text-size-large: 76px;
$text-line-height-large: 86px;
$text-size-large-mobile: 32px;
$text-line-height-large-mobile: 38px;

$text-size-medium: 38px;
$text-line-height-medium: 43px;
$text-size-medium-mobile: 24px;
$text-line-height-medium-mobile: 28px;

$copy-text-size: 18px;
$copy-text-line-height: 26px;
$copy-text-size-small: 14px;
$copy-text-line-height-small: 20px;

$spacing-largest: 80px;
$spacing-large: 60px;
$spacing-medium: 40px;
$spacing-small: 20px;

$section-padding-large: $spacing-large $spacing-large;

$text-width-paragraph: 70%;

@import "@shared/grid.scss";

.login {
  position: fixed;
  display: flex;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  min-height: calc(100vh-75px);
  width: 100vw;

  &-poster {
    display: none;
    width: 40vw;
    height: 44vw;
    max-width: 500px;
    max-height: 550px;
    background-image: url(./poster.svg);
    background-size: cover;

    @include respond-to("large") {
      display: block;
    }
  }

  &-form {
    padding: 0 8vw;

    h1 {
      font-size: $text-size-medium;
      font-family: "Rational Text Medium";
      line-height: 1;
      margin-bottom: 0.75em;

      @include respond-to("medium") {
        font-size: $text-size-large;
      }
    }

    input[type="text"],
    input[type="password"] {
      display: block;
      width: 100%;
      font-family: inherit;
      font-size: $copy-text-size;
      background: $color-grey;
      color: $color-darkblue;
      border: 1px solid $color-darkblue;
      border-radius: 5px;
      padding: 0.85em 1.2em 0.75em;
      margin-bottom: 1em;

      &::-moz-placeholder {
        color: inherit;
      }

      &:-ms-input-placeholder {
        color: inherit;
      }

      &::placeholder {
        color: inherit;
      }
    }

    input[type="submit"] {
      background: $color-yellow;
      border-radius: 5px;
      border: none;
      color: $color-black;
      cursor: pointer;
      float: right;
      font-family: inherit;
      font-size: $copy-text-size;
      line-height: 1;
      padding: 0.85em 1.2em 0.75em;
      text-decoration: none;
      transition: background-color 200ms;

      &:hover {
        background-color: $color-dark-yellow;
      }
    }

    a {
      color: inherit;
      display: block;
      font-family: "Rational Text Medium";
      text-decoration: underline;
      transition: color 200ms;

      &:hover {
        color: $color-link-hover-light;
      }
    }

    p {
      font-size: $copy-text-size;
      line-height: $copy-text-line-height;
      margin-bottom: 1em;
    }
  }

  &-dotBg {
    position: fixed;
    top: calc(12vh + 75px);
    bottom: 12vh;
    left: 3vw;
    right: 3vw;
    background-image: url(./dot.svg);
    background-size: 21px 21px;
  }

  &-logo {
    position: fixed;
    top: 2em;
    left: 2em;
    width: 129px;
    z-index: 1;
  }
}
