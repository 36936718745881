$grid-max-content-width: 1200px;
$grid-outer-padding-default: $spacing-small;
$grid-inner-padding-default: 10px;
$grid-gutters: $spacing-small;
$grid-columns: 12;
$grid-wrapper-top-padding: $spacing-small;
$grid-wrapper-bottom-padding: $spacing-small;

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  }

  @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.section {
  /*padding: $content-padding-sides-mobile;*/
  padding: $spacing-medium 0;

  @include respond-to("medium") {
    padding: $spacing-medium $spacing-medium;
  }

  @include respond-to("large") {
    padding: $spacing-medium $spacing-medium;
  }

  &--backgroundColor {
    background-color: $color-grey;
  }

  &--backgroundColorDark {
    background-color: $color-darkblue;
  }
}

.grid-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $grid-max-content-width;
  padding-bottom: $grid-wrapper-bottom-padding;
  padding-left: $grid-outer-padding-default;
  padding-right: $grid-outer-padding-default;
  padding-top: $grid-wrapper-top-padding;
  width: 100%;

  &:after {
    clear: both;
    content: '';
    display: table;
  }

  &--noSpacingTopBottom {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.grid-container {
  $margin: $grid-inner-padding-default / -1;
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 auto;
  margin-left: $margin;
  margin-right: $margin;
  min-width: 100%;
  position: relative;

  @include respond-to("large") {
    flex-flow: row wrap;
  }
}

@mixin grid-cell($columns: 0, $total-columns: $grid-columns) {
  flex: 0 0 auto;
  padding-left: $grid-inner-padding-default;
  padding-right: $grid-inner-padding-default;
  position: relative;

  @if $columns>0 {
    @include grid-cell-size($columns, $total-columns);
  }
}

@mixin grid-cell-size($columns: 1, $total-columns: $grid-columns) {
  $width: grid-cell-size($columns, $total-columns);
  max-width: $width;
  width: $width;
}

@function grid-cell-size($columns: 1, $total-columns: $grid-columns) {
  @return $columns / $total-columns * 100%;
}

$cellSizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@each $cellSize in $cellSizes {
  .grid-cell--#{$cellSize} {
    @include grid-cell(12, $grid-columns);

    @include respond-to("large") {
      @include grid-cell($cellSize, $grid-columns);
    }
  }
}